import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'

const StandardPage = ({ data }) => {
  const {
    frontmatter: { title, parent, seoTitle },
    html,
  } = data.markdownRemark

  return (
    <Layout title={title} seoTitle={seoTitle} breadcrumbParent={parent}>
      <div className="rn-blog-details pt--110 pb--70 bg_color--1">
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default StandardPage

StandardPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const query = graphql`
  query StandardPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoTitle
        description

        parent {
          title
          url
        }
      }
      html
    }
  }
`
